import PropTypes from 'prop-types'

export const DiscoPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  availabilityCostLabel: PropTypes.string,
})

export type DiscoConsumerUnitIdSources = 'discoClientId' | 'discoConsumerUnitId'

export interface Disco {
  name: string
  availabilityCostLabel?: string
  discoConsumerUnitIdSource: DiscoConsumerUnitIdSources
  discoConsumerUnitIdLabel: string
}

export const defaultAvailabilityCostLabel = 'Custo de disponibilidade'
export const defaultDiscoConsumerUnitIdSource: DiscoConsumerUnitIdSources =
  'discoConsumerUnitId'
export const defaultDiscoConsumerUnitIdLabel = 'N˚ Instalação'

export const defaultDisco = {
  name: '',
  availabilityCostLabel: defaultAvailabilityCostLabel,
  discoConsumerUnitIdSource: defaultDiscoConsumerUnitIdSource,
  discoConsumerUnitIdLabel: defaultDiscoConsumerUnitIdLabel,
}

const ceb: Disco = {
  ...defaultDisco,
  name: 'Neoenergia DF',
  discoConsumerUnitIdLabel: 'Código da instalação',
  discoConsumerUnitIdSource: 'discoClientId',
  // CEB doesn't bill over availability cost
}

const celpe: Disco = {
  ...defaultDisco,
  name: 'Neoenergia PE',
  availabilityCostLabel: 'Importe a somar com imposto',
}

const neoenergiaPe: Disco = {
  ...defaultDisco,
  name: 'Neoenergia PE',
  availabilityCostLabel: 'Importe a somar com imposto',
}

const cemig: Disco = {
  ...defaultDisco,
  name: 'Cemig',
}

const cpflPaulista: Disco = {
  ...defaultDisco,
  name: 'CPFL SP',
  discoConsumerUnitIdLabel: 'Instalação',
}

const elektro: Disco = {
  ...defaultDisco,
  name: 'Elektro',
  availabilityCostLabel: 'DIF. CUSTO.DISP. (TE + TU)',
  discoConsumerUnitIdLabel: 'Seu Código',
  discoConsumerUnitIdSource: 'discoClientId',
}

const light: Disco = {
  ...defaultDisco,
  name: 'Light',
  discoConsumerUnitIdLabel: 'Código de Instalação',
}

const equatorialGo: Disco = {
  ...defaultDisco,
  name: 'Equatorial Goiás',
  availabilityCostLabel: 'CUSTO DISPONIBILIDADE',
  discoConsumerUnitIdLabel: 'Unidade Consumidora',
}

const energisaMT: Disco = {
  ...defaultDisco,
  name: 'Energisa Mato Grosso',
  discoConsumerUnitIdLabel: 'Código do Cliente',
  discoConsumerUnitIdSource: 'discoClientId',
}

const energisaMS: Disco = {
  ...defaultDisco,
  name: 'Energisa Mato Grosso do Sul',
  discoConsumerUnitIdLabel: 'Código do Cliente',
  discoConsumerUnitIdSource: 'discoClientId',
}

const cpflPiratininga: Disco = {
  ...defaultDisco,
  name: 'CPFL Piratininga',
  discoConsumerUnitIdLabel: 'Instalação',
}

const discos = {
  ceb,
  celpe,
  neoenergiaPe,
  cemig,
  cpflPaulista,
  elektro,
  light,
  equatorialGo,
  energisaMT,
  energisaMS,
  cpflPiratininga,
}

export type DiscoNames = keyof typeof discos

export default discos
