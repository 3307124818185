interface FormatterConfig {
  maximumFractionDigits?: number
  prefix?: string
  suffix?: string
}

interface Formatters {
  date: { ptBr: { months: string[] } }
  number: {
    units: { [index: string]: FormatterConfig }
  }
}
const formatters: Formatters = {
  date: {
    ptBr: {
      months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    },
  },
  number: {
    units: {
      brl: { prefix: 'R$', maximumFractionDigits: 2 },
      kwh: { suffix: 'kWh', maximumFractionDigits: 0 },
      kg_co2: { suffix: 'kg de CO₂', maximumFractionDigits: 0 },
      ton: { suffix: 'toneladas', maximumFractionDigits: 1 },
      kg: { suffix: 'kg', maximumFractionDigits: 1 },
    },
  },
}

export default formatters
export type { FormatterConfig, Formatters }
